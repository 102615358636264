<template>
  <v-table ref="table" :columns="columns" :remote="remote"
           :query="query" :pagination="pagination"  row-key="openId">
    <template #code="{row}">
      <author-card :value="row.author" :dataType="$root.dataType"></author-card>
    </template>
    <template #oauthStatus="{row}">
      <span style="color: red;font-size: 14px">{{$dict.store.AUTHORIZATION_STATUS[row.oauthStatus][1]}}</span>
    </template>
    <template #refreshExpireTime="{row}">
      <span>{{transform(row.refreshExpireTime)}}</span>
    </template>
    <!-- <template #operation="{row}">
      <router-link :to="{ name: 'authorAuth', params: { authorId: row.authorId } }" target="_blank">编辑授权</router-link>
    </template> -->
  </v-table>
</template>

<script>
import AuthorCard from "@/components/widgets/author-card"

export default {
  name: "OpenAuthorization",
  props:{
    query:{
      type: Object,
      required: false,
      default:()=> {}
    }
  },
  components:{AuthorCard},
  computed:{
    columns() {
      return [
        { title: `${this.$root.dataTypeLabel}号`, dataIndex: "code", scopedSlots: { customRender: 'code' }, ellipsis: true, width: 240 },
        { title: "互动数据状态", dataIndex: "oauthStatus", scopedSlots: { customRender: 'oauthStatus' }, width: 100 , },
        { title: "互动数据授权有效时间", dataIndex: "refreshExpireTime", scopedSlots: { customRender: 'refreshExpireTime' }, width: 120 },
        // { title: "操作", scopedSlots: { customRender: "operation" }, width: 170 }
      ]
    }
  },
  data() {
    return {
      pagination: { pageSize: 10 },
    }
  },
  methods:{
    init(options = {}) {
      this.search();
    },
    remote(qp) {
      return this.$api.core.OpenAuthAuthorService.query(qp);
    },
    search(reset = false) {
      this.$refs.table.load({ reset: reset });
    },
    transform(v){
      let value = '0分钟'
      if (!v){
        return value
      }
      const sDate = new Date();
      const eDate = new Date(v)
      const timeI = eDate.getTime() - sDate.getTime();
      if (timeI <= 0){
        return value
      }
      let day = Math.floor(timeI / (24 * 3600 * 1000));
      let leave1 = timeI % (24 * 3600 * 1000);
      let hour = Math.floor(leave1 / (3600 * 1000))
      let leave2 = leave1 % (3600 * 1000);
      let minutes = Math.floor(leave2 / (60 * 1000));
      if(day){
        value = `${day}天${hour}小时${minutes}分`
        return value
      }
      if(hour){
        value = `${hour}小时${minutes}分`
        return value
      }
      if(minutes){
        value = `${minutes}分`
        return value
      }
    }
  },
  created() {
  },
  mounted() {
    this.$on('init',this.init)
  }
}
</script>

<style scoped>

</style>