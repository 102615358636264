<template>
  <v-table ref="table" :columns="columns" :remote="remote"
           :query="query" :pagination="pagination"  row-key="id">
    <template #code="{row}">
      <author-card :value="row.author" :dataType="$root.dataType"></author-card>
    </template>
    <template #status="{row}">
      <span style="color: red;font-size: 14px">{{$dict.store.SERVICEPLATFORM_STATUS[row.status][1]}}</span>
    </template>
    <!-- <template #operation="{row}">
      <router-link :to="{ name: 'authorAuth', params: { authorId: row.authorId },query:{ activeTab: 'ServiceAuthorization'}}" target="_blank">编辑授权</router-link>
    </template> -->
  </v-table>
</template>

<script>

import AuthorCard from "@/components/widgets/author-card"

export default {
  name: "ServiceAuthorization",
  props:{
    query:{
      type: Object,
      required: false,
      default:()=> {}
    }
  },
  components:{AuthorCard},
  computed:{
    columns() {
      return [
        { title: `${this.$root.dataTypeLabel}号`, dataIndex: "code", scopedSlots: { customRender: 'code' }, ellipsis: true, width: 240 },
        { title: "直播数据状态", dataIndex: "status", scopedSlots: { customRender: 'status' }, width: 220 },
        // { title: "操作", scopedSlots: { customRender: "operation" }, width: 170 }
      ]
    }
  },
  data() {
    return {
      pagination: { pageSize: 10 },
    }
  },
  methods:{
    init(options = {}) {
      this.search();
    },
    remote(qp) {
      return this.$api.core.Auth.query(qp);
    },
    search(reset = false) {
      this.$refs.table.load({ reset: reset });
    }
  },
  created() {
  },
  mounted() {
    this.$on('init',this.init)
  }
}
</script>

<style scoped>

</style>